<template>
  <div class="">
    <trac-loading v-if="isLoading" />

    <trac-modal
      v-if="addCountReasonModalCtrl"
      class="max-w-sm w-full"
      @close="addCountReasonModalCtrl = false"
    >
      <span class="font-bold text-sm">Enter reason for stock difference:</span>
      <div class="mt-4">
        <trac-input v-model="reason" placeholder="Enter reason" />

        <trac-button
          :disabled="!reason"
          @button-clicked="selectProductUpdateReason"
          class="uppercase w-full mt-5"
        >
          SUBMIT REASON
        </trac-button>
      </div>
    </trac-modal>
    <main class="ml-12">
      <trac-back-button>Submit Count</trac-back-button>
      <div class="flex flex-row mt-12 mb-8 items-end justify-between">
        <div class="flex-col">
          <h2>Inventory Control</h2>
          <h3 class="font-bold mt-4 text-2xl">{{ countData.name }}</h3>
        </div>
        <div class="flex-col">
          <div class="flex flex-row gap-5">
            <trac-button @button-clicked="rejectCount" class="uppercase"
              >Reject Count</trac-button
            >
            <trac-button @button-clicked="recountData" class="uppercase"
              >Recount</trac-button
            >
          </div>
        </div>
      </div>
      <div class="flex flex-row h-full gap-5">
        <div class="px-8 py-8 w-6/12 bg-white rounded-lg mt-16">
          <div class="">
            <div
              v-if="checkAllCounts"
              class="h-full flex flex-col items-center justify-center mx-20"
            >
              <img src="../../../assets/svg/incomplete_count.svg" alt="" />
              <p class="text-sm text-center text-primaryGray mx-auto mt-8">
                Your count on some items didn’t seem to match with the expected
                stock. Click on the caution sign to resolve.
              </p>
            </div>

            <div
              v-else
              class="h-full flex flex-col items-center justify-center mx-20"
            >
              <img src="../../../assets/svg/complete_count.svg" alt="" />
              <p class="text-sm text-center text-primaryGray mx-auto mt-8">
                All counts matched their respective expected counts.
              </p>
            </div>
          </div>
        </div>

        <div class="px-8 py-8 w-6/12 bg-white rounded-lg big-shadow">
          <div class="">
            <div
              class="flex justify-end items-center"
              v-if="checkCountLevel.length > 0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                :stroke="'#de0000'"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" y1="8" x2="12" y2="12" />
                <line x1="12" y1="16" x2="12.01" y2="16" />
              </svg>
              <span class="text-xs pl-2"
                >{{ checkCountLevel.length }} unresolved count</span
              >
            </div>
            <div class="bg-white border mt-5 rounded-lg">
              <table class="w-full rounded-lg table-auto">
                <thead class="bg-blue-100">
                  <tr>
                    <th class="text-left p-4 text-sm font-semibold uppercase">
                      products
                    </th>
                    <th class="text-left p-4 text-sm font-semibold uppercase">
                      counted
                    </th>
                    <th class="text-left p-4 text-sm font-semibold uppercase">
                      expected
                    </th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    v-for="(product, index) in submitedCount"
                    :key="index"
                    :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                  >
                    <td class="p-4 text-xs font-medium">
                      {{ product.title || product.name }}
                    </td>
                    <td class="p-4 text-xs font-medium">
                      <div
                        @click="addReasonToCount(index)"
                        class="inline-flex items-center"
                        :class="
                          product.count_accounted_for ? '' : 'cursor-pointer'
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          :stroke="
                            product.count_accounted_for ? '#3e6f01' : '#de0000'
                          "
                          stroke-width="2.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <line x1="12" y1="8" x2="12" y2="12" />
                          <line x1="12" y1="16" x2="12.01" y2="16" />
                        </svg>
                        <span
                          :class="
                            product.count_accounted_for
                              ? ''
                              : 'border-b-2 border-red-700'
                          "
                          class="inline-block m-1"
                        >
                          {{ product.counted }}
                        </span>
                      </div>
                    </td>
                    <td class="p-4 text-xs font-medium">
                      {{ getProductSpecificStore(product).stockQuantity }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-16">
              <trac-button
                @button-clicked="acceptCount"
                v-if="countCreated"
                class="uppercase w-full"
                >Accept Count</trac-button
              >
              <trac-button
                @button-clicked="createCount"
                v-else
                :variant="'outline'"
                class="uppercase w-full"
                >Create Count</trac-button
              >
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
  SAVE_LOCAL_DB_DATA,
} from '../../../browser-db-config/localStorage';
import products from '../../../store/modules/products';
import { eventBus } from './../../../main';

export default {
  name: 'AcceptCount',
  data() {
    return {
      isLoading: false,
      addCountReasonModalCtrl: false,
      countCreated: false,
      submitedCount: null,
      countData: null,
      selectedIndex: null,
      reasons: ['Theft', 'Damaged', 'Expired', 'Unexplained'],
      reason: '',
    };
  },
  computed: {
    checkAllCounts() {
      return this.submitedCount.find(
        (count) => count.expected !== count.counted
      );
    },
    checkCountLevel() {
      return this.submitedCount.filter((count) => !count.count_accounted_for);
    },
  },
  async created() {
    this.isLoading = true;

    this.countData = GET_LOCAL_DB_DATA('stock-count-data');

    this.submitedCount = GET_LOCAL_DB_DATA('submited-stock-count') || [];
    this.submitedCount = this.submitedCount.map((prod) => {
      return {
        ...prod,
        count_accounted_for:
          prod.counted === this.getProductSpecificStore(prod).stockQuantity,
      };
    });

    this.isLoading = false;
  },
  methods: {
    selectProductUpdateReason() {
      this.submitedCount[this.selectedIndex]['update_reason'] = this.reason;
      this.submitedCount[this.selectedIndex]['count_accounted_for'] = true;
      this.submitedCount = this.submitedCount;
      this.addCountReasonModalCtrl = false;
    },
    addReasonToCount(index) {
      if (this.submitedCount[index].count_accounted_for) {
        return;
      }
      this.selectedIndex = index;
      this.addCountReasonModalCtrl = true;
      this.reason = '';
    },
    getProductSpecificStore(prod) {
      return (
        prod.storeStock.find(
          (ss) => ss.storeID === this.countData.selectedStore._id
        ) || {}
      );
    },
    async createCount() {
      if (this.submitedCount.some((prod) => !prod.count_accounted_for)) {
        eventBus.$emit('trac-alert', {
          message: 'Each count should be accounted for before proceeding',
        });
        return;
      }

      const payload = {
        location: this.countData.selectedStore.name,
        name: this.countData.name,
        products: this.submitedCount.map((prod) => {
          return {
            category: prod.category,
            counted: prod.counted,
            expected: this.getProductSpecificStore(prod).stockQuantity,
            name: prod.title || prod.name,
            product_id: prod._id,
            reason: prod.update_reason || '',
            variant_id: prod.variant_id || '',
          };
        }),
      };

      this.isLoading = true;
      await this.$store.dispatch('CREATE_STOCK_COUNT', payload);

      const res = this.$store.getters['GET_STOCK_COUNTS_RES'];
      this.$store.commit('SETUP_STOCK_COUNT_RES', null);
      if (res.status) {
        // this.$router.push({ name: "submitCount" });
        this.countCreated = true;
        // alert(res.message || res.error);
        eventBus.$emit('trac-alert', { message: res.message || res.error });
      } else {
        // alert(res.message || res.error);
        eventBus.$emit('trac-alert', { message: res.message || res.error });
      }
      this.isLoading = false;
    },
    filterSavedCounts() {
      const data = GET_LOCAL_DB_DATA('submited-saved-stock-count-details');
      if (data) {
        const savedCounts = GET_LOCAL_DB_DATA('save_for_later_prod');
        if (savedCounts) {
          SAVE_LOCAL_DB_DATA(
            'save_for_later_prod',
            savedCounts.filter((count) => count.sub_id !== data.sub_id)
          );
        }
      }
    },
    recountData() {
      this.$router.back();
    },
    rejectCount() {
      this.$router.replace({ name: 'StockCount' });
    },
    async acceptCount() {
      // if (this.submitedCount.some((prod) => !prod.count_accounted_for)) {
      //   eventBus.$emit("trac-alert", {
      //     message: "Add a reason for each count before proceeding",
      //   });
      //   return;
      // }

      const payload = this.submitedCount.map((prod) => {
        return prod.is_variant || prod.variant_id
          ? {
              business_id: GET_USER_BUSINESS_ID(),
              // stock: this.getProductSpecificStore(prod).stockQuantity || prod.counted,
              stock: prod.counted,
              store_id:
                this.countData.selectedStore._id ||
                this.countData.saved_counted_details.store_id,
              update_reason: prod.update_reason || '',
              variation: prod.variant_id ? true : prod.is_variant || false,
              variant_id: prod.variant_id,
              _id: prod.p_id,
            }
          : {
              business_id: GET_USER_BUSINESS_ID(),
              // stock: this.getProductSpecificStore(prod).stockQuantity || prod.counted,
              stock: prod.counted,
              store_id:
                this.countData.selectedStore._id ||
                this.countData.saved_counted_details.store_id,
              update_reason: prod.update_reason || '',
              variation: prod.variant_id ? true : prod.is_variant || false,
              // variant_id: prod.variant_id,
              _id: prod._id,
            };
      });

      this.isLoading = true;
      await this.$store.dispatch('ACCEPT_COUNT', payload);

      const res = this.$store.getters['GET_STOCK_COUNTS_RES'];
      this.$store.commit('SETUP_STOCK_COUNT_RES', null);
      if (res.status) {
        await this.$store.dispatch('REFRESH_ALL_PRODUCTS');
        DELETE_LOCAL_DB_DATA('submited-stock-count');
        DELETE_LOCAL_DB_DATA('stock-count-data');
        this.filterSavedCounts();
        this.$router.push({ name: 'StockCount' });
        eventBus.$emit('trac-alert', { message: res.message || res.error });
      } else {
        // alert(res.message || res.error);
        eventBus.$emit('trac-alert', { message: res.message || res.error });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
